<template>
    <div class="container-fluid agri_res_manag_dashbord">
    <iq-card class="mt-2">
       <template v-slot:headerTitle>
            <h4 class="card-title">{{ $t('research_manage.r_m_dashboard') }}</h4>
        </template>
    </iq-card>
        <b-overlay :show="loading">
            <b-row>
                <b-col sm="4">
                    <div class="card mb-4">
                        <div class="card-body card_body_item">
                            <div class="icon_wrapper">
                                <i class="fas fa-user-plus"></i>
                            </div>
                            <router-link :to="{ path: '/agri-research-service/research-management/proposal-invitation' }">
                                <div class="dist">
                                    <h3>{{$n(totalInvitation, { useGrouping: false })}}</h3>
                                    <p>{{ $t('research_manage.n_p_invited') }}</p>
                                </div>
                            </router-link>
                        </div>
                    </div>
                </b-col>
                <b-col sm="4">
                    <div class="card mb-4">
                        <div class="card-body card_body_item">
                            <div class="icon_wrapper">
                                <i class="fa fa-paper-plane"></i>
                            </div>
                            <router-link :to="{ path: '/agri-research-service/research-management/research-project-report' }">
                                <div class="dist">
                                    <h3>{{$n(totalProposal, { useGrouping: false })}}</h3>
                                    <p>{{ $t('research_manage.t_p_submission') }}</p>
                                </div>
                            </router-link>
                        </div>
                    </div>
                </b-col>
                <b-col sm="4">
                    <div class="card mb-4">
                        <div class="card-body card_body_item">
                            <div class="icon_wrapper">
                                <i class="fa fa-check"></i>
                            </div>
                            <router-link :to="{ path: '/agri-research-service/research-management/proposal-approval' }">
                                <div class="dist">
                                    <h3>{{$n(totalApprove, { useGrouping: false })}}</h3>
                                    <p>{{ $t('research_manage.p_approved') }}</p>
                                </div>
                            </router-link>
                        </div>
                    </div>
                </b-col>
                <b-col sm="4">
                    <div class="card mb-4">
                        <div class="card-body card_body_item">
                            <div class="icon_wrapper">
                                <i class="fa fa-ban"></i>
                            </div>
                            <router-link :to="{ path: '/agri-research-service/research-management/research-project-report', query: {status: 3} }">
                                <div class="dist">
                                    <h3>{{$n(totalRejection, { useGrouping: false })}}</h3>
                                    <p>{{ $t('research_manage.n_p_reject') }}</p>
                                </div>
                            </router-link>
                        </div>
                    </div>
                </b-col>
                <b-col sm="4">
                    <div class="card mb-4">
                        <div class="card-body card_body_item">
                            <div class="icon_wrapper">
                                <i class="fas fa-running"></i>
                            </div>
                            <div class="dist">
                                <h3>{{$n(totalOngoing, { useGrouping: false })}}</h3>
                                <p>{{ $t('research_manage.n_r_ongoing') }}</p>
                            </div>
                        </div>
                    </div>
                </b-col>
                <!-- <div class="col-md-4 mt-3">
                    <div class="card mb-3" style="border: 1px solid #898A96;">
                        <div class="card-header bg-success text-white row w-100 m-auto">
                            <div class="col-12 p-0 text-center" style="font-size:14px;">{{ $t('research_manage.n_p_invited') }}</div>
                        </div>
                            <div class="card-body row mt-4 mb-4">
                            <div class="col-6 text-center"><i class="fas fa-user-plus" style="font-size:70px;"></i></div>
                        <div class="col-6" style="font-size:40px;text-align: center;">{{$n(totalInvitation, { useGrouping: false })}}</div>
                        </div>
                    </div>
                </div> -->
                <!-- <div class="col-md-4 mt-3">
                    <div class="card mb-3" style="border: 1px solid #898A96;">
                        <div class="card-header bg-success text-white row w-100 m-auto">
                            <div class="col-12 p-0 text-center" style="font-size:14px;">{{ $t('research_manage.t_p_submission') }}</div>
                        </div>
                        <div class="card-body row mt-4 mb-4">
                            <div class="col-6 text-center"><i class="fa fa-paper-plane" style="font-size:70px;"></i></div>
                            <div class="col-6" style="font-size:40px;text-align: center;">{{$n(totalProposal, { useGrouping: false })}}</div>
                        </div>
                    </div>
                </div> -->
                <!-- <div class="col-md-4 mt-3">
                    <div class="card mb-3" style="border: 1px solid #898A96;">
                        <div class="card-header bg-success text-white row w-100 m-auto">
                            <div class="col-12 p-0 text-center" style="font-size:14px;">{{ $t('research_manage.p_approved') }}</div>
                        </div>
                        <div class="card-body row mt-4 mb-4">
                            <div class="col-6 text-center"><i class="fa fa-check" style="font-size:70px;"></i></div>
                            <div class="col-6" style="font-size:40px;text-align: center;">{{$n(totalApprove, { useGrouping: false })}}</div>
                        </div>
                    </div>
                </div> -->
                <!-- <div class="col-md-4 mt-3">
                    <div class="card mb-3" style="border: 1px solid #898A96;">
                        <div class="card-header bg-success text-white row w-100 m-auto">
                            <div class="col-12 p-0 text-center" style="font-size:14px;">{{ $t('research_manage.n_p_reject') }}</div>
                        </div>
                        <div class="card-body row mt-4 mb-4">
                            <div class="col-6 text-center"><i class="fa fa-ban" style="font-size:70px;"></i></div>
                            <div class="col-6" style="font-size:40px;text-align: center;">{{$n(totalRejection, { useGrouping: false })}}</div>
                        </div>
                    </div>
                </div> -->
                <!-- <div class="col-md-4 mt-3">
                    <div class="card mb-3" style="border: 1px solid #898A96;">
                        <div class="card-header bg-success text-white row w-100 m-auto">
                            <div class="col-12 p-0 text-center" style="font-size:14px;">{{ $t('research_manage.n_r_ongoing') }}</div>
                        </div>
                        <div class="card-body row mt-4 mb-4">
                            <div class="col-6 text-center"><i class="fas fa-running" style="font-size:70px;"></i></div>
                            <div class="col-6" style="font-size:40px;text-align: center;">{{$n(totalOngoing, { useGrouping: false })}}</div>
                        </div>
                    </div>
                </div> -->
            </b-row><!-- /.row -->
        </b-overlay>
    </div>
</template>

<script>
import RestApi, { agriResearchServiceBaseUrl } from '@/config/api_config'
import { researchDashboardApi } from '../../api/routes'

export default {
  name: 'UiDataTable',
  data () {
    return {
      loading: false,
      totalInvitation: 0,
      totalProposal: 0,
      totalApprove: 0,
      totalRejection: 0,
      totalOngoing: 0
    }
  },
  computed: {
  },
  async created () {
    this.loadData()
  },
  methods: {
    series () {
      return this.seriesData
    },
    loadData () {
        this.loading = true
        RestApi.getData(agriResearchServiceBaseUrl, researchDashboardApi).then(response => {
            if (response.success) {
                this.totalInvitation = response.data.total_proposal_invitation
                this.totalProposal = response.data.total_proposal_submission
                this.totalApprove = response.data.total_proposal_approve
                this.totalRejection = response.data.total_proposal_reject
                this.totalOngoing = response.data.total_proposal_ongoing
            }
            this.loading = false
        })
    }
  }
}
</script>

<style scoped>
.bg-delivery {
    background-color: #008082;
}
.hrclass {
    border-top: 1px solid rgb(86, 19, 149) !important;
    margin-top: 5px !important;
    margin-bottom: 5px !important;
}
.seeds-box {
    border-radius: 3px;
    box-shadow: 0 2px 5px 0 rgba(10, 7, 7, 0.16), 0 2px 10px 0 rgba(17, 10, 10, 0.12);
    padding: 10px 0px;
    text-align: right;
    display: block;
    margin-top: 20px;
}
.seeds-box-icon {
    background-color: #609513 !important;
    margin: 0 auto;
    margin-top: -20px;
    font-size: 12px !important;
    height: 40px;
    padding-top: 7px;
}
.seeds-box-icon span {
    color: rgb(148, 104, 104);
    display: table-cell;
    text-align: center;
    vertical-align: middle;
}
.info h4 {
    font-size: 26px;
    letter-spacing: 2px;
    text-transform: uppercase;
}
.info > p {
    color: #6b5959;
    font-size: 16px;
    padding-top: 10px;
    text-align: justify;
}

.agri_res_manag_dashbord .card {
    cursor: pointer;
    box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
}
.agri_res_manag_dashbord .card:hover {
    box-shadow: rgba(50, 50, 93, 0.25) 0px 13px 27px -5px, rgba(0, 0, 0, 0.3) 0px 8px 16px -8px;
}
.agri_res_manag_dashbord .card_body_item {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    padding: 1.6rem 1.2rem;
}
.agri_res_manag_dashbord .card_body_item .icon_wrapper {
    background: #F3ECFD;
    padding: 15px 0px;
    border-radius: 50%;
    width: 56px;
    height: 56px;
    text-align: center;
    margin-right: 20px;
}
.agri_res_manag_dashbord .card_body_item .icon_wrapper i {
    font-size: 1.68rem;
    color: #3db043;
}
.agri_res_manag_dashbord .card_body_item .dist h3 {
    font-size: 2.3rem;
    font-weight: 600;
    margin-bottom: 0;
    line-height: 2.4rem;
}
.agri_res_manag_dashbord .card_body_item .dist p {
    margin-bottom: 0;
    color: #9b9bb3;
}
</style>
